export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Something went wrong...',
  subheader: `We're sorry, something went terribly wrong. Please try again in a little while.`,
  buttonText: 'Return to homepage',
}
